import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import Video from "../videos/istockphoto3.mp4";
import Layout from "../components/layout-2";
import SEO from "../components/seo";
import { Box, Flex } from "@chakra-ui/layout";
import { Button, ButtonGroup, Icon, useMediaQuery } from "@chakra-ui/react";
import {
  HumanNutrition,
  AnimalNutrition,
  Food,
  PersonalCare,
  IndustrialUse,
} from "../components/industries";
import {
  IoArrowForwardOutline,
  IoArrowDownOutline,
  IoMail,
  IoCall,
} from "react-icons/io5";
import HumanNutritionIcon from "../images/human-nutrition.svg";
import AnimalNutritionIcon from "../images/animal-nutrition.svg";
import PersonalCareIcon from "../images/personal-care.svg";
import IndustrialUsesIcon from "../images/industrial.svg";
import { FaRegCheckCircle } from "react-icons/fa";
import QualityIcon from "../images/quality.svg";
import ShoppingCartIcon from "../images/shopping-cart.svg";
import CustomerDelightIcon from "../images/customer-delight.svg";
import CustomSolutionsIcon from "../images/custom-solutions.svg";
import { TLink } from "../components/tlink";
import { TransitionContext } from "../context/transition-context";

const IndexPage = () => {
  const industriesRef = useRef();
  const [svgSmallerThan478Query, svgSmallerThan550Query] = useMediaQuery([
    "(max-width: 478px)",
    "(max-width: 550px)",
  ]);

  const [svgSmallerThan478, setSvgSmallerThan478] = useState(false);
  const [svgSmallerThan550, setSvgSmallerThan550] = useState(false);

  // const setTransition = useContext(TransitionContext);

  useEffect(() => {
    setSvgSmallerThan478(svgSmallerThan478Query);
  }, [svgSmallerThan478Query]);

  useEffect(() => {
    setSvgSmallerThan550((prevState) => !prevState);
  }, [svgSmallerThan550Query]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTransition.setTransition(false);
  //   }, 1800);
  // });

  const scrollToIndustries = () => {
    var headerOffset = 80;
    industriesRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    // var offsetPosition = elementPosition - headerOffset;

    // window.scrollTo({
    // top: offsetPosition,
    // behavior: "smooth",
    // });
  };

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Kaminds Nutrichem Private Limited",
    alternateName: "Kaminds",
    url: "https://www.kaminds.com/",
    logo:
      "https://www.kaminds.com/static/d2284c154057c362c68fe045665929d5/63311/Trademark%20Kaminds_RGB%20Logo_Logo_Trademark.png",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+917568015258",
        contactType: "customer service",
        areaServed: "IN",
        availableLanguage: ["en", "Hindi"],
      },
      {
        "@type": "ContactPoint",
        telephone: "+917568015258",
        contactType: "sales",
        areaServed: "IN",
        availableLanguage: ["en", "Hindi"],
      },
    ],
    sameAs: [
      "https://www.linkedin.com/company/kaminds",
      "https://www.kaminds.com/",
    ],
  };

  return (
    <Layout>
      <SEO
        title="Kaminds Nutrichem Private Limited - Passion, People, Partnership"
        description="Kaminds is a leading manufacturing and distribution company of raw materials for food and beverage, animal nutrition, human nutrition, personal care and industrial use"
        schemaMarkup={schemaMarkup}
        keywords="About Kaminds, Kaminds Website, global nutraceutical suppliers, nutraceutical ingredients manufacturers in India, imported nutraceutical ingredients, ingredients distributor in India"
        links={[{ rel: "canonical", href: "https://www.kaminds.com" }]}
      />
      <Box position="relative" background="black">
        <Box
          position="absolute"
          top="50%"
          left="53%"
          transform="translate(-53%, -50%)"
          // top={["27%", "27%", "10%", "20%", "27%"]}
          // left={["7%", "10%", "20%", "20%", "20%"]}
          height={["40%", "40%", "50%", "50%", "50%"]}
          width={["90%", "80%", "80%", "65%"]}
          zIndex="1"
        >
          <Flex
            direction="column"
            justifyContent="space-between"
            height="100%"
            width={["95%", "95%", "90%", "90%", "80%"]}
          >
            <Box>
              <Box
                as="h1"
                fontFamily="Fjalla One !important"
                fontSize={[
                  "60px",
                  "80px",
                  "70px",
                  "70px",
                  "90px",
                  "120px",
                  // "120px",
                ]}
                color="#fff"
                lineHeight={["65px", "80px", "80px", "80px", "95px", "125px"]}
              >
                PASSION, PEOPLE, PARTNERSHIP
              </Box>
              <Box
                as="p"
                color="#fff"
                fontSize={["16px", "20px", "20px", "24px", "26px", "27px"]}
                paddingLeft="10px"
                marginTop="15px"
                marginBottom="20px"
                lineHeight={["25px", "33px", "33px", "33px", "33px"]}
              >
                Kaminds is your one-stop shop for sourcing the finest of
                ingredients from the world’s leading manufacturers.
              </Box>
            </Box>
            <Button
              width={["80%", "65%", "50%", "50%", "40%"]}
              background="#fff"
              color="#282765"
              rightIcon={
                <IoArrowDownOutline
                  style={{ marginRight: "-15px", marginLeft: "10px" }}
                />
              }
              colorScheme="#fff"
              padding="15px"
              _hover={{ background: "#282765", color: "#fff" }}
              variant="outline"
              borderRadius="25px"
              onClick={scrollToIndustries}
              // width="150px"
            >
              EXPLORE OUR MARKETS
            </Button>
          </Flex>
        </Box>
        <Box
          display={["block", "none", "none", "none", "none"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../images/beach-small.jpg"
            // width={800}
            height={1080}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.8" }}
            // style={{ width: "100%" }}
          />
        </Box>
        <Box
          display={["none", "block", "none", "none", "none"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../images/beach-medium.jpg"
            width={800}
            height={900}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.8" }}
            // style={{ width: "100%" }}
          />
        </Box>
        <Box
          display={["none", "none", "block", "none", "none"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../images/beach.jpg"
            width={1920}
            height={1200}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.8" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "block", "none"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../images/beach.jpg"
            width={1920}
            height={1000}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.8" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "none", "block"]}
          position="relative"
          marginTop="60px"
        >
          <StaticImage
            src="../images/beach.jpg"
            width={1920}
            height={900}
            placeholder="none"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="banner"
            style={{ display: "block", opacity: "0.8" }}
          />
        </Box>
      </Box>
      {/* <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}

      <Box marginTop="100px" padding="2em">
        <Flex
          maxWidth="1300px"
          margin="auto"
          justifyContent={[
            "center",
            "center",
            "center",
            "space-between",
            "space-between",
          ]}
          alignItems="center"
          wrap="wrap"
        >
          <Flex
            background="linen"
            padding="2em"
            // paddingRight="100px"
            width={["100%", "100%", "100%", "57%", "57%"]}
            borderRadius="30px"
            boxShadow="3px 6px 8px rgba(0,0,0,.2)"
            // grow="1"
            // marginLeft={["0px", "0px", "-70px", "-70px"]}
            // marginTop={["30px", "30px", "0px", "0px"]}
            direction="column"
            justifyContent="center"
            // paddingTop="100px"
            // paddingRight={["0px", "0px", "20px", "25px"]}
          >
            <Box
              as="h2"
              fontSize={["30px", "30px", "30px", "30px", "35px"]}
              color="#252869"
              lineHeight="2.2rem"
            >
              Global supplier of Chemicals &amp; Nutraceutical ingredients for{" "}
              <Box as="span" fontWeight="700">
                Human Nutrition
              </Box>
              ,{" "}
              <Box as="span" fontWeight="700">
                Animal Nutrition
              </Box>{" "}
              and{" "}
              <Box as="span" fontWeight="700">
                Personal Care
              </Box>
              .
            </Box>
            <Box as="p" paddingTop="20px" fontSize="18px" color="#252869">
              Kaminds is a leading global distributor of essential raw material
              to the pharmaceutical, personal care industries as well as
              ingredients for human and animal nutrition. Our vastly spread
              channel of distribution system ensures a smooth delivery of
              products.
            </Box>
            <Button
              borderRadius="25px"
              width={["80%", "60%", "50%", "60%", "40%"]}
              marginTop="20px"
              colorScheme="#fff"
              variant="outline"
              background="#fff"
              color="#252869"
              _hover={{
                background: "#252869",
                color: "#fff",
              }}
            >
              <TLink to="/products">VIEW ALL PRODUCTS</TLink>
            </Button>
          </Flex>
          <Flex
            marginTop={["30px", "30px", "30px", "0px", "0px"]}
            direction="column"
            justifyContent="center"
            width={["100%", "70%", "70%", "40%", "40%"]}
            overflow="hidden"
            borderRadius="30px"
            marginLeft={["0px", "0px", "0px", "0px"]}
          >
            {/* <Flex justify="center"> */}
            <StaticImage
              src="../images/grid.png"
              placeholder="tracedSVG"
              tracedSVGOptions={{ color: "#282765" }}
              // width={2560}
              height={500}
              alt="image"
            />
          </Flex>
        </Flex>
      </Box>
      <Box
        background="#fff"
        padding="2em"
        // paddingLeft="0"
        marginTop={["40px", "40px", "80px", "120px", "120px"]}
        position="relative"
        // height="500px"
      >
        <Flex
          maxWidth="1300px"
          margin="auto"
          justifyContent={[
            "center",
            "center",
            "space-between",
            "space-between",
            "space-between",
          ]}
          wrap="wrap-reverse"
        >
          <Flex
            direction="column"
            width={["100%", "100%", "38.5%", "19%", "19%"]}
            justifyContent="space-between"
          >
            <Flex
              width="100%"
              height="48.5%"
              direction="column"
              background="aliceblue"
              color="#252869"
              padding="1em"
              borderRadius="30px"
              boxShadow="3px 6px 8px rgba(0,0,0,.2)"
              marginTop={["30px", "30px", "0px", "0px", "0px"]}
            >
              <ShoppingCartIcon />
              <Box as="h3" fontSize="30px" marginTop="15px" paddingLeft="9px">
                One Stop Solution
              </Box>
              <Box as="p" marginTop="10px" paddingLeft="9px">
                An extensive range of products to cater to your diverse
                requirements.
              </Box>
            </Flex>
            <Flex
              width="100%"
              height="48.5%"
              direction="column"
              background="linen"
              color="#252869"
              padding="1em"
              borderRadius="30px"
              boxShadow="3px 6px 8px rgba(0,0,0,.2)"
              marginTop={["30px", "30px", "0px", "0px", "0px"]}
            >
              <QualityIcon />
              <Box as="h3" fontSize="30px" marginTop="15px" paddingLeft="9px">
                Quality
              </Box>
              <Box as="p" marginTop="10px" paddingLeft="9px">
                We maintain the highest quality standards in the industry.
              </Box>
            </Flex>
          </Flex>
          <Flex
            display={["none", "none", "none", "flex", "flex"]}
            direction="column"
            width="39%"
            borderRadius="30px"
            boxShadow="3px 6px 8px rgba(0,0,0,.2)"
            overflow="hidden"
          >
            <StaticImage
              // height={350}
              style={{ height: "100%" }}
              src="../images/why-choose-us.jpg"
            />
          </Flex>
          <Flex
            direction="column"
            width={["100%", "100%", "58.5%", "39%", "39%"]}
            justifyContent="space-between"
          >
            <Flex
              width="100%"
              height="48.5%"
              direction="column"
              background="linen"
              color="#252869"
              padding="1em"
              textAlign={["left", "left", "right", "right", "right"]}
              alignItems={[
                "flex-start",
                "flex-start",
                "flex-end",
                "flex-end",
                "flex-end",
              ]}
              borderRadius="30px"
              boxShadow="3px 6px 8px rgba(0,0,0,.2)"
              marginTop={["30px", "30px", "0px", "0px", "0px"]}
            >
              <Box as="h3" fontSize={["30px", "40px", "50px", "50px", "50px"]}>
                WHY
                <br /> <span style={{ fontWeight: "600" }}>CHOOSE</span>
                <br /> <span style={{ fontWeight: "600" }}>US?</span>
              </Box>
              <Box as="p" marginTop="10px" width="90%">
                Everyday we work hard to make life of our clients better and
                happier.
              </Box>
            </Flex>
            <Flex
              width="100%"
              height={["", "", "48.5%", "48.5%", "48.5%"]}
              // height={["0%", "0%", "48.5%", "48.5%", "48.5%"]}
              justifyContent="space-between"
              wrap="wrap"
            >
              <Flex
                width={["100%", "100%", "48.5%", "48.5%", "48.5%"]}
                direction="column"
                background="aliceblue"
                color="#252869"
                padding="1em"
                borderRadius="30px"
                boxShadow="3px 6px 8px rgba(0,0,0,.2)"
                marginTop={["30px", "30px", "0px", "0px", "0px"]}
              >
                <Box style={{ paddingLeft: "9px" }}>
                  <CustomerDelightIcon />
                </Box>
                <Box as="h3" fontSize="30px" marginTop="15px" paddingLeft="9px">
                  Customer Delight
                </Box>
                <Box as="p" marginTop="10px" paddingLeft="9px">
                  Customer satisfaction remains at top of the priority list.
                </Box>
              </Flex>
              <Flex
                width={["100%", "100%", "48.5%", "48.5%", "48.5%"]}
                direction="column"
                background="aliceblue"
                color="#252869"
                padding="1em"
                borderRadius="30px"
                boxShadow="3px 6px 8px rgba(0,0,0,.2)"
                marginTop={["30px", "30px", "0px", "0px", "0px"]}
              >
                <Box style={{ paddingLeft: "9px" }}>
                  <CustomSolutionsIcon />
                </Box>
                <Box as="h3" fontSize="30px" marginTop="15px" paddingLeft="9px">
                  Custom Solutions
                </Box>
                <Box as="p" marginTop="10px" paddingLeft="9px">
                  We can provide solutions tailor made as per your requirement.
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box
        ref={industriesRef}
        padding={["2em 1.2em 3em", "2em 2em 3em"]}
        marginTop={["40px", "40px", "80px", "120px", "120px"]}
      >
        <Box maxWidth="1700" margin="auto">
          {/* <Box
            color="#fff"
            textAlign="center"
            color="#252869"
            // fontFamily="Roboto Condensed !important"
            fontSize="45px"
            fontWeight="400"
          >
            Industries We Serve
          </Box>
          <Box
            height="4px"
            width="85px"
            background="#d61215"
            margin="5px auto 25px"
          ></Box> */}
          <Flex
            justifyContent={[
              "space-around",
              "space-around",
              "space-around",
              "space-around",
              "space-between",
            ]}
            paddingTop="30px"
            wrap="wrap"
            // direction={["column", "column", "column", "row"]}
          >
            <HumanNutrition />
            <AnimalNutrition />
            <Food />
            <PersonalCare />
            <IndustrialUse />
          </Flex>
        </Box>
        {/* <Box
        as="p"
        background="#fff"
        width="45%"
        margin="0 auto"
        marginTop="40px"
        padding="1.5em"
        z-index="1"
        position="relative"
        color="#2a2665"
        fontWeight="500"
        // border="2px solid #2a2665"
      >
        <i>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta nulla
          unde ea harum tenetur voluptates quisquam similique nostrum,
          consectetur molestias cum modi veritatis fuga alias vel, nobis esse
          est aliquid.
        </i>
      </Box> */}
      </Box>
      <Box
        marginTop="80px"
        paddingBottom="80px"
        paddingLeft="2em"
        paddingRight="2em"
      >
        <Box
          borderRadius="30px"
          background="linen"
          maxWidth="1000px"
          width="100%"
          margin="0 auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Box
              as="p"
              textAlign="center"
              marginTop="30px"
              fontSize="45px"
              fontWeight="400"
              color="#252869"
            >
              Contact Us
            </Box>
            <Box
              as="p"
              color="#252869"
              fontSize="22px"
              lineHeight="2em"
              textAlign="center"
            >
              We are here to help you!
            </Box>
          </Box>
          <Flex
            marginBottom="-2em"
            justifyContent="space-between"
            direction={["column", "column", "row", "row"]}
            marginTop="30px"
            width="80%"
          >
            <Box
              width={["100%", "100%", "49%", "49%"]}
              background="#fff"
              borderRadius="30px"
              padding="2em"
              boxShadow="3px 6px 8px rgba(0,0,0,.2)"
            >
              <Box textAlign="center" paddingBottom="1em">
                <Box
                  as="p"
                  color="#1d1a5f"
                  fontWeight="500"
                  fontSize="25px"
                  paddingBottom="0.6em"
                >
                  Email us
                </Box>
                <Box as="p" color="#1d1a5f">
                  <IoMail
                    style={{
                      color: "#252869",
                      display: "inline",
                      marginRight: "10px",
                      marginBottom: "2px",
                    }}
                  />
                  <a href="mailto:contact@kaminds.com">contact@kaminds.com</a>
                </Box>
              </Box>
              <Box textAlign="center">
                <Box
                  as="p"
                  color="#252869"
                  fontWeight="500"
                  fontSize="25px"
                  paddingBottom="0.6em"
                >
                  Talk to us
                </Box>
                <Box as="p" color="#252869">
                  <IoCall
                    style={{
                      color: "#252869",
                      display: "inline",
                      marginRight: "10px",
                      marginBottom: "2px",
                    }}
                  />
                  <a href="tel:+917568015258">(+91) 75680 15258</a>
                </Box>
              </Box>
            </Box>
            <Box
              width={["100%", "100%", "49%", "49%"]}
              background="#fff"
              borderRadius="30px"
              padding="2em"
              boxShadow="3px 6px 8px rgba(0,0,0,.2)"
              marginTop={["1.7em", "1.7em", "0", "0"]}
            >
              <Box textAlign="center" paddingBottom="1em">
                <Box
                  as="p"
                  textAlign="center"
                  color="#252869"
                  fontWeight="500"
                  fontSize="25px"
                  paddingBottom="0.6em"
                >
                  Message us
                </Box>
                <Box>
                  <TLink to="/contact">
                    <Button
                      borderRadius="25px"
                      marginTop="1.7em"
                      width="40%"
                      colorScheme="#252869"
                      color="#252869"
                      background="#fff"
                      variant="outline"
                      _hover={{ color: "#fff", background: "#252869" }}
                    >
                      Contact
                    </Button>
                  </TLink>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Layout>
  );
};

export default IndexPage;
