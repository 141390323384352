import * as React from "react";
import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { globalHistory } from "@reach/router";
import { TLink } from "./tlink";
import { IoChevronDownOutline } from "react-icons/io5";
import {
  Link as Anchor,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  List,
  ListItem,
  ListIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon,
  MenuCommand,
  MenuDivider,
  OrderedList,
  UnorderedList,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import { withTheme } from "@emotion/react";
const Header2 = ({ siteTitle }) => {
  const [showIndustriesSubMenu, setShowIndustriesSubMenu] = useState(false);
  const subMenu = useRef();
  // const [mobileNavbar, setMobileNavbar] = useState(false);
  // const [mobileNavbarQuery] = useMediaQuery(["(max-width: 920px)"]);
  const [activeSubMenu, setActiveSubMenu] = useState(false);
  // const [HeaderBG, setHeaderBG] = useState(false);
  const [industriesMenu, setIndustriesMenu] = useState(false);
  // const [aboutMenu, setAboutMenu] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setActiveSubMenu(false);
    },
  });
  // const location = window.location.pathname;
  // console.log(location);
  // const changeHeaderBG = () => {
  //   if (window.scrollY >= 40) {
  //     setHeaderBG(true);
  //   } else {
  //     setHeaderBG(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", changeHeaderBG);
  // }, []);

  // useEffect(() => {
  //   changeHeaderBG();
  // }, []);

  const toggleSubMenu = () => {
    setActiveSubMenu(!activeSubMenu);
    // console.log(content.current.scrollHeight);
  };

  // useEffect(() => {
  //   setMobileNavbar(mobileNavbarQuery);
  // }, [mobileNavbarQuery]);

  useEffect(() => {
    setShowIndustriesSubMenu(true);
  });

  return (
    <>
      <Box
        as="header"
        background="white"
        marginBottom="0"
        position="fixed"
        top="0"
        left="0"
        width="100%"
        zIndex="1"
        paddingTop="5px"
        paddingBottom="5px"
        transition="all 0.5s"
        paddingLeft="2em"
        paddingRight="2em"
        zIndex="10"
        //   boxShadow="0 8px 6px -6px #999;"
      >
        <Box
          margin="0 auto"
          maxWidth="1300"
          // padding: `1.45rem 1.0875rem`,
          display="flex"
          justifyContent="space-between"
        >
          <Box
            style={{
              "-webkit-tap-highlight-color": "transparent",
            }}
          >
            {/* <a href="/"> */}
            <TLink to="/">
              <Box
                display={["none", "none", "flex", "flex", "flex"]}
                flexDirection="column"
              >
                <StaticImage
                  src="../images/Trademark Kaminds_RGB Logo_Logo_Trademark.png"
                  width={95}
                  height={95}
                  placeholder="none"
                  formats={["PNG"]}
                  quality={100}
                  objectFit="contain"
                  alt="Logo"
                />
                {/* <h6
                style={{
                  marginTop: "0.8rem",
                  marginBottom: "0",
                  color: "#d61216",
                  fontSize: "1.0rem",
                }}
              >
                Kaminds <span style={{ color: "#282863" }}>Nutrichem</span>
              </h6> */}
              </Box>
            </TLink>
            <TLink to="/">
              <Box
                display={["flex", "flex", "none", "none", "none"]}
                flexDirection="column"
              >
                <StaticImage
                  src="../images/Trademark Kaminds_RGB Logo_Logo_Trademark.png"
                  width={70}
                  height={70}
                  placeholder="none"
                  formats={["PNG"]}
                  quality={100}
                  objectFit="contain"
                  alt="Logo"
                />
                {/* <h6
                style={{
                  marginTop: "0.8rem",
                  marginBottom: "0",
                  color: "#d61216",
                  fontSize: "0.7rem",
                }}
              >
                Kaminds <span style={{ color: "#282863" }}>Nutrichem</span>
              </h6> */}
              </Box>
            </TLink>
            {/* </a> */}
          </Box>
          <Box
            display={["none", "none", "flex", "flex", "flex"]}
            alignItems="center"
          >
            <UnorderedList
              style={{
                color: "#282863",
                listStyle: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0",
                fontWeight: "400",
                fontSize: "22px",
                paddingTop: "5px",
              }}
            >
              {/* <ListItem
                style={{
                  margin: "0 20px",
                }}
              >
                <TLink
                  to="/"
                  styles={{
                    color: "inherit",
                    listStyle: "none",
                    textDecoration: "none",
                    fontFamily: "Roboto Condensed !important",
                  }}
                >
                  Home
                </TLink>
              </ListItem> */}
              <ListItem style={{ margin: "0 20px" }}>
                <TLink
                  to="/about"
                  styles={{
                    color: "inherit",
                    listStyle: "none",
                    textDecoration: "none",
                  }}
                >
                  About
                </TLink>
              </ListItem>
              {/* <Link
                to="/industries"
                style={{
                  color: "inherit",
                  listStyle: "none",
                  textDecoration: "none",
                }}
              >
                Industries
              </Link> */}

              <ListItem style={{ margin: "0 20px" }}>
                <TLink
                  to="/products"
                  styles={{
                    color: "inherit",
                    listStyle: "none",
                    textDecoration: "none",
                  }}
                >
                  Products
                </TLink>
              </ListItem>
              <ListItem
                style={{
                  margin: "0",
                }}
              >
                {/* <TLink
                  to="/industries"
                  style={{
                    color: "inherit",
                    listStyle: "none",
                    textDecoration: "none",
                  }}
                >
                  Industries
                </TLink> */}
                <Menu isOpen={industriesMenu}>
                  <MenuButton
                    onClick={() => navigate("/applications/")}
                    fontFamily="inherit"
                    onMouseEnter={() => {
                      setIndustriesMenu(true);
                    }}
                    onMouseLeave={() => {
                      setIndustriesMenu(false);
                    }}
                    px={4}
                    py={2}
                    transition="all 0.2s"
                    _hover={{ bg: "#282765", color: "#fff" }}
                  >
                    Applications
                  </MenuButton>
                  <MenuList
                    display={showIndustriesSubMenu ? "block" : "none"}
                    background="#fff"
                    onMouseEnter={() => {
                      setIndustriesMenu(true);
                    }}
                    onMouseLeave={() => {
                      setIndustriesMenu(false);
                    }}
                  >
                    <TLink to="/applications/human-nutrition">
                      <MenuItem
                        background="#fff"
                        color="#000"
                        _hover={{ bg: "#282765", color: "#fff" }}
                      >
                        Human Nutrition
                      </MenuItem>
                    </TLink>
                    <TLink to="/applications/animal-nutrition">
                      <MenuItem
                        background="#fff"
                        color="#000"
                        _hover={{ bg: "#282765", color: "#fff" }}
                      >
                        Animal Nutrition
                      </MenuItem>
                    </TLink>
                    <TLink to="/applications/food">
                      <MenuItem
                        background="#fff"
                        color="#000"
                        _hover={{ bg: "#282765", color: "#fff" }}
                      >
                        Food
                      </MenuItem>
                    </TLink>
                    <TLink to="/applications/personal-care">
                      <MenuItem
                        background="#fff"
                        color="#000"
                        _hover={{ bg: "#282765", color: "#fff" }}
                      >
                        Personal Care
                      </MenuItem>
                    </TLink>
                    <TLink to="/applications/industrial-use">
                      <MenuItem
                        background="#fff"
                        color="#000"
                        _hover={{ bg: "#282765", color: "#fff" }}
                      >
                        Industrial Use
                      </MenuItem>
                    </TLink>
                  </MenuList>
                </Menu>
              </ListItem>
              <ListItem style={{ margin: "0 20px" }}>
                <TLink
                  to="/contact"
                  styles={{
                    color: "inherit",
                    listStyle: "none",
                    textDecoration: "none",
                  }}
                >
                  Contact
                </TLink>
              </ListItem>
              <ListItem style={{ margin: "0 20px" }}>
                <TLink
                  to="/careers"
                  styles={{
                    color: "inherit",
                    listStyle: "none",
                    textDecoration: "none",
                  }}
                >
                  Careers
                </TLink>
              </ListItem>
            </UnorderedList>
          </Box>
          <Box
            display={["block", "block", "none", "none", "none"]}
            alignSelf="center"
            lineHeight="0"
            marginTop="5px"
          >
            <button onClick={onOpen}>
              <span
                style={{
                  display: "block",
                  backgroundColor: "#000",
                  margin: "4px auto",
                  height: "2px",
                  width: "20px",
                }}
              ></span>
              <span
                style={{
                  display: "block",
                  backgroundColor: "#000",
                  margin: "4px auto",
                  height: "2px",
                  width: "20px",
                }}
              ></span>
              <span
                style={{
                  display: "block",
                  backgroundColor: "#000",
                  margin: "4px auto",
                  height: "2px",
                  width: "20px",
                }}
              ></span>
            </button>
          </Box>
        </Box>
      </Box>
      <Box display={["block", "block", "none", "none", "none"]}>
        {/* <Button colorScheme="teal" onClick={onOpen}>
      Open
    </Button> */}
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          // finalFocusRef={btnRef}
        >
          <DrawerOverlay background="#2a2665">
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader></DrawerHeader>
              <DrawerBody margin="auto" width="100%">
                <UnorderedList
                  style={{
                    width: "100%",
                    height: "60%",
                    listStyle: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    margin: "0",
                    fontWeight: "500",
                    fontSize: "23px",
                    paddingTop: "10px",
                  }}
                >
                  <ListItem marginBottom="2em">
                    <TLink
                      to="/"
                      styles={{
                        color: "#252869",
                        listStyle: "none",
                        textDecoration: "none",
                        fontFamily: "Roboto Condensed !important",
                      }}
                    >
                      Home
                    </TLink>
                  </ListItem>
                  <ListItem marginBottom="2em">
                    <TLink
                      to="/products"
                      styles={{
                        color: "#252869",
                        listStyle: "none",
                        textDecoration: "none",
                        fontFamily: "Roboto Condensed !important",
                      }}
                    >
                      Products
                    </TLink>
                  </ListItem>
                  <ListItem
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "2em",
                    }}
                  >
                    <Box
                      onClick={toggleSubMenu}
                      fontFamily="Roboto Condensed !important"
                    >
                      Applications
                      <IoChevronDownOutline
                        style={{
                          transition: "transform 0.3s ease",
                          display: "inline",
                          marginLeft: "11px",
                          marginRight: "-30px",
                          transform: activeSubMenu
                            ? "rotate(-180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </Box>
                    <Box
                      overflow="hidden"
                      maxHeight={
                        activeSubMenu ? subMenu.current.scrollHeight : "0px"
                      }
                      ref={subMenu}
                      transition="max-height 0.2s ease"
                    >
                      <UnorderedList
                        style={{
                          width: "100%",
                          // height: "60%",
                          // maxHeight: "0px",
                          listStyle: "none",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "0",
                          fontWeight: "500",
                          fontSize: "21px",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <ListItem>
                          <TLink
                            to="/applications/human-nutrition"
                            styles={{
                              color: "#252869",
                              listStyle: "none",
                              textDecoration: "none",
                              fontFamily: "Roboto Condensed !important",
                            }}
                          >
                            Human Nutrition
                          </TLink>
                        </ListItem>
                        <ListItem>
                          <TLink
                            to="/applications/animal-nutrition"
                            styles={{
                              color: "#252869",
                              listStyle: "none",
                              textDecoration: "none",
                              fontFamily: "Roboto Condensed !important",
                            }}
                          >
                            Animal Nutrition
                          </TLink>
                        </ListItem>
                        <ListItem>
                          <TLink
                            to="/applications/food"
                            styles={{
                              color: "#252869",
                              listStyle: "none",
                              textDecoration: "none",
                              fontFamily: "Roboto Condensed !important",
                            }}
                          >
                            Food
                          </TLink>
                        </ListItem>
                        <ListItem>
                          <TLink
                            to="/applications/personal-care"
                            styles={{
                              color: "#252869",
                              listStyle: "none",
                              textDecoration: "none",
                              fontFamily: "Roboto Condensed !important",
                            }}
                          >
                            Personal Care
                          </TLink>
                        </ListItem>
                        <ListItem>
                          <TLink
                            to="/applications/industrial-use"
                            styles={{
                              color: "#252869",
                              listStyle: "none",
                              textDecoration: "none",
                              fontFamily: "Roboto Condensed !important",
                            }}
                          >
                            Industrial Use
                          </TLink>
                        </ListItem>
                      </UnorderedList>
                    </Box>
                  </ListItem>
                  <ListItem marginBottom="2em">
                    <TLink
                      to="/about"
                      styles={{
                        color: "#252869",
                        listStyle: "none",
                        textDecoration: "none",
                        fontFamily: "Roboto Condensed !important",
                      }}
                    >
                      About
                    </TLink>
                  </ListItem>
                  <ListItem marginBottom="2em">
                    <TLink
                      to="/contact"
                      styles={{
                        color: "#252869",
                        listStyle: "none",
                        textDecoration: "none",
                        fontFamily: "Roboto Condensed !important",
                      }}
                    >
                      Contact
                    </TLink>
                  </ListItem>
                  <ListItem marginBottom="2em">
                    <TLink
                      to="/careers"
                      styles={{
                        color: "#252869",
                        listStyle: "none",
                        textDecoration: "none",
                        fontFamily: "Roboto Condensed !important",
                      }}
                    >
                      Careers
                    </TLink>
                  </ListItem>
                </UnorderedList>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
    </>
  );
};

Header2.propTypes = {
  siteTitle: PropTypes.string,
};

Header2.defaultProps = {
  siteTitle: ``,
};

export default Header2;
