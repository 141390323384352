import React, { useState, useEffect, useContext } from "react";
import { css, jsx } from "@emotion/react";
import { AnimatePresence, motion } from "framer-motion";
import { TransitionContext } from "../context/transition-context";
import { StaticImage } from "gatsby-plugin-image";

export const parentVariants = {
  visible: {
    opacity: 0,
    transition: {
      ease: "easeIn",
      duration: 0.5,
      // staggerChildren: 0.2,
      // staggerDirection: -1,
    },
  },

  hidden: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 0.5,
      // staggerChildren: 0.2,
      // staggerDirection: -1,
    },
  },
};

export const childVariants = {
  visible: {
    scaleX: 1,
    transition: {
      ease: "circInOut",
      duration: 1,
    },
  },
  hidden: {
    scaleX: 0,
    transition: {
      ease: "circInOut",
      duration: 1,
    },
  },
};

export const childVariantHead = {
  visible: {
    scale: 1,
    rotate: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
      delay: 1.2,
    },
  },
  hidden: {
    scale: 0,
    rotate: 15,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
      delay: 0.4,
    },
  },
};

export const LoadingAnimation = () => {
  const { transition } = useContext(TransitionContext);

  const [playState, setPlayState] = useState(transition);

  useEffect(() => {
    setPlayState(transition);
  }, [transition]);

  return (
    <AnimatePresence exitBeforeEnter>
      {playState && (
        <motion.div
          variants={parentVariants}
          initial="visible"
          animate="hidden"
          exit="visible"
          aria-hidden={true}
          css={css`
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 11;
            pointer-events: none;
            transform-origin: left;
            > div {
              height: 25vh;
              margin-top: -5vh;
              width: 100vw;
              background-color: #282765;
              transform-origin: right;
            }
            > figure {
              position: fixed;
              top: calc(50% - 6rem);
              left: calc(47% - 0rem);
              transform-origin: center;
              width: 5rem;
              height: 5rem;
              z-index: 12;
              padding: 0;
              margin: 0 auto;
              > div {
                width: 100%;
                height: 100%;
              }
            }
          `}
        >
          <motion.figure
            variants={childVariantHead}
            // exit="hidden"
            // initial="hidden"
            // animate="visible"
            key={5}
          >
            <StaticImage
              src="../images/Kaminds_Logo_White_No Background.png"
              width={120}
              height={120}
              placeholder="none"
              formats={["PNG"]}
              // objectFit="contain"
              layout="fixed"
              alt="Logo"
            />
          </motion.figure>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingAnimation;
