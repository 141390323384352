import React, { useContext } from "react";
import { css, jsx } from "@emotion/react";
import { Link, navigate } from "gatsby";
import { TransitionContext } from "../context/transition-context";

// const transitionStyles = css`
//   color: #d8cf25;
//   text-decoration: none;
//   border-bottom: 2px solid currentColor;
//   font-weight: bold;
//   outline: none;
//   -webkit-tap-highlight-color: transparent;
// `;

export const TLink = (props) => {
  const { to, children, styles } = props;

  const { setTransition } = useContext(TransitionContext);

  return (
    <Link
      to={to}
      title={to === "/" ? "Home" : to.replace(/\//g, " ")}
      css={[styles]}
      onClick={(e) => {
        e.preventDefault();

        // setTransition(false);

        setTimeout(() => {
          navigate(to);
        }, 1);
      }}
    >
      {children}
    </Link>
  );
};
