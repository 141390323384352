import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Flex } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { TLink } from "../components/tlink";

export const HumanNutrition = (props) => {
  const [showContent, setShowContent] = useState(false);
  return (
    <Box
      position="relative"
      width={[
        "85%",
        "90%",
        "calc(100% / 3 - 17px)",
        "calc(100% / 3 - 25px)",
        "calc(100% / 5 - 17px)",
      ]}
      marginTop="35px"
      // width="19%"
      borderRadius="30px"
      overflow="hidden"
      height="560px"
      transition="transform .2s"
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
      _hover={{
        transform: "scale(1.1)",
      }}
    >
      <TLink to="/applications/human-nutrition">
        <StaticImage
          placeholder="blurred"
          src="../images/human-nutrition.jpg"
          style={{
            height: "100%",
          }}
        />
        <Flex
          bottom="0px"
          left="0px"
          top="0px"
          right="0px"
          position="absolute"
          direction="column"
          justifyContent="flex-end"
          padding="1.5em"
        >
          <Box
            as="h2"
            color="#fff"
            fontSize="40px"
            // fontFamily="Source Serif Pro !important"
            fontWeight="800"
          >
            Human Nutrition
          </Box>
          <Box
            maxHeight={showContent ? "300px" : "0px"}
            overflow="hidden"
            transition="0.8s ease"
          >
            <Box
              marginTop="10px"
              as="p"
              color="#fff"
              fontSize="16px"
              // maxHeight="100px"
              // fontFamily="Source Serif Pro !important"
              fontWeight="500"
            >
              Nutraceuticals can fortify us with the right nutrients that are
              required for good health.
            </Box>
            <Button
              width={["40%", "30%", "60%", "40%", "50%"]}
              borderRadius="30px"
              marginTop="15px"
              background="#fff"
              color="#252869"
              colorScheme="#fff"
              borderColor="#fff"
              _hover={{ background: "#252869", color: "#fff" }}
            >
              Explore
            </Button>
          </Box>
        </Flex>
      </TLink>
    </Box>
  );
};

export const AnimalNutrition = () => {
  const [showContent, setShowContent] = useState(false);
  return (
    <Box
      width={[
        "85%",
        "90%",
        "calc(100% / 3 - 17px)",
        "calc(100% / 3 - 25px)",
        "calc(100% / 5 - 17px)",
      ]}
      marginTop="35px"
      // width="19%"
      borderRadius="30px"
      position="relative"
      overflow="hidden"
      height="560px"
      transition="transform .2s"
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
      _hover={{
        transform: "scale(1.1)",
      }}
    >
      <TLink to="/applications/animal-nutrition">
        <StaticImage
          placeholder="blurred"
          src="../images/animal-nutrition.jpg"
          style={{
            height: "100%",
          }}
        />
        <Flex
          bottom="0px"
          left="0px"
          top="0px"
          right="0px"
          position="absolute"
          direction="column"
          justifyContent="flex-end"
          padding="1.5em"
        >
          <Box as="h2" color="#fff" fontSize="40px" fontWeight="800">
            Animal Nutrition
          </Box>
          <Box
            maxHeight={showContent ? "300px" : "0px"}
            overflow="hidden"
            transition="0.8s ease"
          >
            <Box
              marginTop="10px"
              as="p"
              color="#fff"
              fontSize="16px"
              // maxHeight="100px"
              fontWeight="500"
            >
              In our never-ending quest to provide the best animal nutrition
              solutions, we source only the finest ingredients.
            </Box>
            <Button
              width={["40%", "30%", "60%", "40%", "50%"]}
              borderRadius="30px"
              marginTop="15px"
              background="#fff"
              color="#252869"
              colorScheme="#fff"
              borderColor="#fff"
              _hover={{ background: "#252869", color: "#fff" }}
            >
              Explore
            </Button>
          </Box>
        </Flex>
      </TLink>
    </Box>
  );
};

export const Food = () => {
  const [showContent, setShowContent] = useState(false);
  return (
    <Box
      width={[
        "85%",
        "90%",
        "calc(100% / 3 - 17px)",
        "calc(100% / 3 - 25px)",
        "calc(100% / 5 - 17px)",
      ]}
      marginTop="35px"
      // width="19%"
      borderRadius="30px"
      position="relative"
      overflow="hidden"
      height="560px"
      transition="transform .2s"
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
      _hover={{
        transform: "scale(1.1)",
      }}
    >
      <TLink to="/applications/food">
        <StaticImage
          placeholder="blurred"
          src="../images/food.jpg"
          style={{
            height: "100%",
          }}
        />
        <Flex
          bottom="0px"
          left="0px"
          top="0px"
          right="0px"
          position="absolute"
          direction="column"
          justifyContent="flex-end"
          padding="1.5em"
        >
          <Box as="h2" color="#fff" fontSize="40px" fontWeight="800">
            Food
          </Box>
          <Box
            maxHeight={showContent ? "300px" : "0px"}
            overflow="hidden"
            transition="0.8s ease"
          >
            <Box
              marginTop="10px"
              as="p"
              color="#fff"
              fontSize="16px"
              // maxHeight="100px"
              fontWeight="500"
            >
              In our never-ending quest to provide the best food solutions, we
              source only the finest ingredients.
            </Box>
            <Button
              width={["40%", "30%", "60%", "40%", "50%"]}
              borderRadius="30px"
              marginTop="15px"
              background="#fff"
              color="#252869"
              colorScheme="#fff"
              borderColor="#fff"
              _hover={{ background: "#252869", color: "#fff" }}
            >
              Explore
            </Button>
          </Box>
        </Flex>
      </TLink>
    </Box>
  );
};

export const PersonalCare = () => {
  const [showContent, setShowContent] = useState(false);
  return (
    <Box
      width={[
        "85%",
        "90%",
        "calc(100% / 3 - 17px)",
        "calc(100% / 3 - 25px)",
        "calc(100% / 5 - 17px)",
      ]}
      marginTop="35px"
      // width="19%"
      borderRadius="30px"
      overflow="hidden"
      height="560px"
      position="relative"
      transition="transform .2s"
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
      _hover={{
        transform: "scale(1.1)",
      }}
    >
      <TLink to="/applications/personal-care">
        <StaticImage
          placeholder="blurred"
          src="../images/personal-care.jpg"
          style={{
            height: "100%",
          }}
        />
        <Flex
          bottom="0px"
          left="0px"
          top="0px"
          right="0px"
          position="absolute"
          direction="column"
          justifyContent="flex-end"
          padding="1.5em"
        >
          <Box as="h2" color="#fff" fontSize="40px" fontWeight="800">
            Personal Care
          </Box>
          <Box
            maxHeight={showContent ? "300px" : "0px"}
            overflow="hidden"
            transition="0.8s ease"
          >
            <Box
              marginTop="10px"
              as="p"
              color="#fff"
              fontSize="16px"
              // maxHeight="100px"
              fontWeight="500"
            >
              Personal care product formulators are incorporating a new
              generation of ingredient technologies that are high-performance,
              skin friendly and non-synthetic.
            </Box>
            <Button
              width={["40%", "30%", "60%", "40%", "50%"]}
              borderRadius="30px"
              marginTop="15px"
              background="#fff"
              color="#252869"
              colorScheme="#fff"
              borderColor="#fff"
              _hover={{ background: "#252869", color: "#fff" }}
            >
              Explore
            </Button>
          </Box>
        </Flex>
      </TLink>
    </Box>
  );
};

export const IndustrialUse = () => {
  const [showContent, setShowContent] = useState(false);
  return (
    <Box
      width={[
        "85%",
        "90%",
        "calc(100% / 3 - 17px)",
        "calc(100% / 3 - 25px)",
        "calc(100% / 5 - 17px)",
      ]}
      marginTop="35px"
      // width="19%"
      borderRadius="30px"
      overflow="hidden"
      position="relative"
      height="560px"
      transition="transform .2s"
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
      _hover={{
        transform: "scale(1.1)",
      }}
    >
      <TLink to="/applications/industrial-use">
        <StaticImage
          placeholder="blurred"
          src="../images/industrial-use.jpg"
          style={{
            height: "100%",
          }}
        />
        <Flex
          bottom="0px"
          left="0px"
          top="0px"
          right="0px"
          position="absolute"
          direction="column"
          justifyContent="flex-end"
          padding="1.5em"
        >
          <Box as="h2" color="#fff" fontSize="40px" fontWeight="800">
            Industrial Use
          </Box>
          <Box
            maxHeight={showContent ? "300px" : "0px"}
            overflow="hidden"
            transition="0.8s ease"
          >
            <Box
              marginTop="10px"
              as="p"
              color="#fff"
              fontSize="16px"
              // maxHeight="100px"
              fontWeight="500"
            >
              We offer a wide range of chemicals and raw materials as well as
              innovative ingredients to create environmentally friendly products
              that don’t inhibit performance.
            </Box>
            <Button
              width={["40%", "30%", "60%", "40%", "50%"]}
              borderRadius="30px"
              marginTop="15px"
              background="#fff"
              color="#252869"
              colorScheme="#fff"
              borderColor="#fff"
              _hover={{ background: "#252869", color: "#fff" }}
            >
              Explore
            </Button>
          </Box>
        </Flex>
      </TLink>
    </Box>
  );
};
