import React, { useState } from "react";

export const TransitionContext = React.createContext();

export const TransitionContextProvider = (props) => {
  const [transition, setTransition] = useState(true);

  return (
    <TransitionContext.Provider
      value={{
        transition: transition,
        setTransition: setTransition,
      }}
    >
      {props.children}
    </TransitionContext.Provider>
  );
};
