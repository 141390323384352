import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineLinkedin } from "react-icons/ai";
import { color } from "@chakra-ui/styled-system";
import { TLink } from "./tlink";
import {
  Link as Anchor,
  Box,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <>
      <Box background="#f2f2f2" paddingLeft="2em" paddingRight="2em">
        <Box
          paddingTop="50px"
          paddingBottom="10px"
          maxWidth="1500px"
          width="100%"
          margin="50px auto 0px auto"
        >
          <Box
            display="flex"
            justifyContent={[
              "space-around",
              "space-around",
              "space-around",
              "space-around",
              "space-between",
            ]}
            flexDirection={["column", "column", "row", "row"]}
            alignItems={["center", "center", "start", "start"]}
            flexWrap="wrap"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems={["center", "center", "start", "start"]}
            >
              <Box marginLeft={["0", "0", "-15px", "-15px"]}>
                <StaticImage
                  src="../images/Kaminds_RGB_Logo_No_Tagline.png"
                  width={100}
                  height={100}
                  placeholder="none"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  objectFit="contain"
                  alt="Logo"
                />
              </Box>
              <Box marginTop="30px" display="flex">
                <a href="https://wa.me/message/ANP2C6YRMKR5I1" target="_blank">
                  <FaWhatsapp
                    fontSize="30px"
                    style={{ marginRight: "20px", color: "#252869" }}
                  />
                </a>
                {/* <FiFacebook
                  fontSize="30px"
                  style={{ marginRight: "20px", color: "#252869" }}
                /> */}
                <a
                  href="https://in.linkedin.com/company/kaminds"
                  target="_blank"
                >
                  <AiOutlineLinkedin
                    fontSize="30px"
                    style={{ color: "#252869" }}
                  />
                </a>
              </Box>
              <Box marginTop="30px"></Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={["center", "center", "start", "start"]}
              marginTop={["2.5em", "2.5em", "0", "0"]}
              color="#252869"
            >
              <Box as="h2" fontWeight="500" fontSize="20px">
                Applications
              </Box>
              <Box
                marginTop={["25px", "25px", "30px", "30px"]}
                textAlign={["center", "center", "start", "start"]}
              >
                <UnorderedList
                  textDecoration="none"
                  listStyleType="none"
                  marginLeft="0"
                >
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/applications/human-nutrition">
                      Human Nutrition
                    </TLink>
                  </ListItem>
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/applications/animal-nutrition">
                      Animal Nutrition
                    </TLink>
                  </ListItem>
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/applications/food">Food</TLink>
                  </ListItem>
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/applications/personal-care">
                      Personal Care
                    </TLink>
                  </ListItem>
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/applications/industrial-use">
                      Industrial Use
                    </TLink>
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
            <Box
              display="flex"
              color="#252869"
              //   textAlign="center"
              flexDirection="column"
              alignItems={["center", "center", "start", "start"]}
              //   width="20%"
              //   alignItems="center"
              marginTop={["2.5em", "2.5em", "0", "0"]}
            >
              <Box as="h2" fontWeight="500" fontSize="20px" fontSize="20px">
                Quick Links
              </Box>
              <Box
                marginTop={["25px", "25px", "30px", "30px"]}
                textAlign={["center", "center", "start", "start"]}
              >
                <UnorderedList
                  textDecoration="none"
                  listStyleType="none"
                  marginLeft="0"
                >
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/products">Products</TLink>
                  </ListItem>
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/about">About Us</TLink>
                  </ListItem>
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/contact">Contact Us</TLink>
                  </ListItem>
                  <ListItem
                    marginBottom={[
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 4)",
                      "calc(1.45rem / 2)",
                      "calc(1.45rem / 2)",
                    ]}
                  >
                    <TLink to="/careers">Careers</TLink>
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
            {/* <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              maxWidth={["100%", "100%", "100%", "100%", "25%"]}
              width="100%"
              marginTop={["3em", "3em", "3em", "3em", "0"]}
            > */}
            <Box
              width={["90%", "70%", "80%", "35%", "35%"]}
              marginTop={["30px", "30px", "30px", "0px", "0px"]}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3555.0220551557477!2d75.77023021504667!3d26.99785208309112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db312bc5351bf%3A0x7a83b88d0f0b5f84!2sKaminds%20Nutrichem%20Private%20Limited!5e0!3m2!1sen!2sin!4v1624270335999!5m2!1sen!2sin"
                width="100%"
                height="250"
                style={{
                  border: "0",
                  borderRadius: "30px",
                  overflow: "hidden",
                }}
                allowFullScreen
                loading="lazy"
              ></iframe>
            </Box>
            {/* <Box as="h2" fontWeight="500" fontSize="20px">
                Reach Us
              </Box> */}
            {/* <Box fontSize="30px" marginTop="10px">
                <IoLocationOutline />
              </Box>
              <Box as="p" fontSize="18px" marginTop="25px" textAlign="center">
                S-52, RIICO Commercial Complex, Road No. 14, VKI, Jaipur -
                302013
              </Box> */}
            {/* </Box> */}
          </Box>
          <Box
            as="p"
            fontSize="14px"
            marginTop="5px"
            marginBottom="15px"
            textAlign="center"
            color="#252869"
          >
            &copy; Kaminds Nutrichem Private Limited, {new Date().getFullYear()}
            . All rights reserved
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
