/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { PageTransition } from "./page-transition";
import LoadingAnimation from "./loading-animation";
import { TransitionContextProvider } from "../context/transition-context";
import IndexPage from "../pages/index";
import Header2 from "./header-2";
import Footer from "./footer";
import "./layout.css";

const Layout2 = ({ children }) => {
  //   const data = useStaticQuery(graphql`
  //     query SiteTitleQuery {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  //   `);

  return (
    <>
      <TransitionContextProvider>
        {/* <div
           style={{
             margin: `0 auto`,
             minHeight: 0,
           }}
         >
           <div
             style={{
               overflowY: "scroll",
               overflowX: "hidden",
               height: "100vh",
               minHeight: 0,
             }}
           > */}
        <Header2 siteTitle="Title" />
        {/* <div
               style={{
                 margin: `0 auto`,
                 maxWidth: 960,
                 padding: `0 1.0875rem 1.45rem`,
               }}
             > */}
        <main>{children}</main>
        <Footer />
        {/* </div> */}
        {/* </div>
         </div> */}
        {/* <LoadingAnimation /> */}
      </TransitionContextProvider>
    </>
  );
};

Layout2.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout2;
